import '../../assets/css/modals.css'; 

const ConfirmModal = (props) => {

  const closeModel = (modalId) => {
    window.$("#"+modalId).modal('hide');
  }

  const handleOkCallback =() => {
    if(props.okCallback){
      let params = props?.okParams || [];
       props.okCallback(...params);
    } 
    else{  
      closeModel(modalId);
    }
  }

  const handleCancelCallback =() => {
    if(props.cancelCallback){
      let params = props?.cancelParams || [];
       props.cancelCallback(...params);
    } 
    else{  
      closeModel(modalId);
    }
  }  

  const modalId = props.id ? props.id:'modal'
    return (
        <div id={modalId} className="modal fade" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content2">
            <div className="modal-header" style={{direction : props?.dir ? props.dir : 'ltr'}}>
              <button data-dismiss="modal" className="close2">&times;</button>
              {props?.title == 'no_title' ? '' : (props?.title || 'Message')}
            </div>
            <div className="modal-body">              
              {props.children}
            </div>

            <div className="modal-footer">
              <button type="button" onClick={handleOkCallback} className={props?.okBtnClass ? props.okBtnClass : "btn btn-success"}>{props?.okLabel || 'Yes'}</button>           
              <button type="button" onClick={handleCancelCallback} className="btn btn-danger">{props?.cancelLabel || 'Cancel'}</button>           
            </div>

          </div>
        </div>  
      </div>        
    )    
}


export default ConfirmModal;