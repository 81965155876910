
import {useState, useContext, useEffect, useRef} from 'react';
import {Form, Formik} from 'formik';
import Elements from '../elements/elements';
import {AuthContext} from '../../context/AuthContext'
import { UserContext as language } from '../../context/languageContext';
import { ProfileContext } from '../../context/ProfileContext';
import MessageModal from '../modals/messageModal';
import FileUploadBtn from '../elements/fileUploadBtn';
import loadinImg from '../../assets/images/Loading_icon.gif'
import defaultImage from '../../assets/images/profile-default.png';
import config from '../config';
import ChildProfileSchema from '../schema/childProfileSchema';
import { errorFocus, scrollTo } from '../../helpers/functions';
import {editProfile} from '../../helpers/functions';

const {CustomInput, DateInput, SelectInput, RadioInput, LoadingButton} = Elements;
var responseStatus = null;
var response = null;

const ChildProfile = (props) => {

    const [isLoading, setLoading] = useState(false);
    const [userData, setUserData] = useState([]);
    const [message, setMessage] = useState({title:"", body:""});
    const [profileImg, setProfileImg] = useState(loadinImg);
    const Auth = useContext(AuthContext);
    const Profile = useContext(ProfileContext)
    const Lang = useContext(language);
    const imgref = useRef(null);
    const [username, setUsername] = useState(Auth.getUserName(false));
    const [disableParentFeild, setDisableParentFeild] = useState(false);
    const [isEdit, setEdit] = useState(false);

    const searchParams = new URLSearchParams(document.location.search);
    const focus = searchParams.get('focus');    
    const focusOn = focus || '';    

    useEffect(()=>{
        Profile.getProfileData().then((res)=>{
            if(res.data?.payload){
                setUserData(res.data.payload);
                setProfileImg(res.data.payload.profile_img);
                setDisableParentFeild(res.data.payload?.parent_status==1 ? true:false)
                //console.log(res.data.payload);
            }

        }).catch((err)=>{

        });

        if(focus){
            editProfile(setEdit); // to make feilds in edition state
            const element = document.getElementById(focusOn);
            element.focus();              
            scrollTo(focusOn);
            element.style.borderColor= '#e62828';
        }


    },[])
    
   
    const initValues = {
        id: userData.id || 0,
        //dob: userData?.dob || '' ,
        //dob_day:userData?.dob?.day || '',
        //dob_month:userData?.dob?.month || '',
        //dob_year:userData?.dob?.year || '',        
        first_name: userData?.first_name != 'notSet' ? (userData?.first_name || '') : '', 
        last_name: userData?.last_name != 'notSet' ? (userData?.last_name || '') : '', 
        parent_email : userData?.parent_email || '',
        email : userData?.email || '',
        //email:  userData?.email || '', 
        //mobile: userData?.mobile_number || '', 
        //mobile_prefix: userData?.mobile_prefix || '',
        allow_sharing_projects:userData?.allow_sharing_projects || '',
        show_sign_language_videos:userData?.show_sign_language_videos || '',
        password:'', 
    };

    const onSubmitForm = async (values, actions) => {

        setLoading(true);
        response = await Profile.updateChildProfile(values);
        setLoading(false);
        responseStatus = response.status;
        if(responseStatus == 1){
            //window.$('.reg-input').addClass('readonly-field');
            //window.$(".reg-input").prop('readonly', true);
            //window.$(".select-date").prop('disabled', true);
            //window.$(".user-dob").prop('disabled', true);
            window.$('.eye-icon').addClass('profile-eye');
            window.$('.save-profile').addClass('hide');
            window.$('.edit-icon').removeClass('hide');
            window.$('.profile-user-name').removeClass('hide');
            window.$('.upload-img').addClass('hide');
            window.$('#usernameIcon1').removeClass('hide');
            window.$('#usernameIcon2').addClass('hide');
            window.$('#parent_email').addClass('parent_email');
            window.$('#parent_email').removeClass('parent_email2');

            //actions.resetForm();            
            actions.setFieldValue('password', "", false);
            setMessage({title:Lang.edit_profile_success, body:Lang.data_saved_success});
            setUsername(values.first_name+' '+values.last_name);
            window.$('#general-message').modal('show');
            setEdit(false);
        }
        else if(responseStatus==3 || responseStatus==5 || responseStatus==2 || responseStatus==6){}
        else{
            setMessage({title:"", body:"Failed Data Save"});
            window.$('#general-message').modal('show');
        }        
    }

    const handleErrorImage = () => {
        imgref.current.src=defaultImage
    }
    return (
        <>
        <Formik
      enableReinitialize
      initialValues={initValues}
      validationSchema={ChildProfileSchema}
      onSubmit={onSubmitForm}  
    >

{({ values, errors, setFieldValue, setErrors, touched  }) => {
    if(responseStatus == 3){
        setErrors({'email':'email_registered_before'});
    }    

    else if(responseStatus == 2){
        errors.parent_email = 'parent_email_not_exist';
    }       
    else if(responseStatus == 6){
        errors.parent_email = 'parent_reach_max_child';
    }       
    // if(responseStatus == 5){
    //     errors.mobile = 'Mobile Number Not Available!';
    // }      
    responseStatus= null;

    errorFocus(errors);

    return(
        <Form style={{'backgroundColor':'#e9e9e9'}}>

<div className="child-container" style={{display: 'none'}}>
    <div className="profile-header">
        <p className='profile-type-name'>{Lang.child_profile}</p>
        <div className="profile-image user-img float-md-right">
            <div className='change-image-icon-container p-relative' style={{width:'300px'}}>
            <img style={{'margin': '0 auto','display': 'block'}} ref={imgref} src={profileImg} onError={handleErrorImage} alt="" />
            <p class="profile-user-name">{username}</p>
                <FileUploadBtn
                functions =
                {{
                    beforeupload:() => { setProfileImg(loadinImg)},
                    onuploaderror:() => {setProfileImg(defaultImage)},
                    afterupload:(data) => {setProfileImg(data.payload.img)},
                }} 
                accept="image/*"

                id={`profile_image`} 
                url={`uploadProfileImage`} 
                data={{userId:Auth.getUserId()}} 
                title={Lang.change_photo}/>
            </div>
        </div>
    </div>
</div>

<div className="section-container">
<div className='content-container reg-container profile-box'>
        <div className="top-titles">
            <p className="profile-icon" id="usernameIcon1"><img src={process.env.PUBLIC_URL + "/assets/images/parent_profile.svg"} alt={Lang.child_profile} /><p>{Lang.child_profile}</p></p>
            <p className="profile-icon hide" id="usernameIcon2"><img src={process.env.PUBLIC_URL + "/assets/images/parent_profile.svg"} alt={Lang.child_profile} /><p>{Lang.child_profile_edit}</p></p>
            <p className="edit-icon" onClick={()=>editProfile(setEdit)}><img className="fa-pencil" src={process.env.PUBLIC_URL + "/assets/images/editPen.svg"}/>{Lang.edit}</p>
        </div>
        {/* Start Form section */}
                       
        <div className="reg-form" style={{'width':'90%'}}>
        <div className='change-image-icon-container p-relative profile-image-container'>
            <img className='user-image' ref={imgref} src={profileImg} onError={handleErrorImage} alt="" />
            <p class="profile-user-name">{username}</p>
            <div style={{'margin':' 0 20px'}}>

                <FileUploadBtn className="upload-img hide" 
                functions =
                {{
                    beforeupload:() => { setProfileImg(loadinImg)},
                    onuploaderror:() => {setProfileImg(defaultImage)},
                    afterupload:(data) => {setProfileImg(data.payload.img)},
                }} 
                accept="image/*"
                id={`profile_image`} 
                url={`uploadProfileImage`} 
                data={{userId:Auth.getUserId()}} 
                title={Lang.change_photo}/>
            </div>
            </div>
            <CustomInput name="id" id="id" type="hidden"/>
            {/* <div className="form-group" style={{"margin": "0"}}>
            <DateInput selected={startDate} maxDate ={maxDate} name="dob" id="dob" type="text" placeholderText={Lang.date_of_birth+'*'} className="reg-input date-birth"/>
            </div> */}

            <CustomInput name="email" id="email" type="text" placeholder=" "  className="reg-input hide" disabled />
            {/* <div className="form-group" style={{"margin": "0"}}>
              <div className="reg-field">
                <label htmlFor="email">{Lang.email_or_user+'*'}</label>
                  <CustomInput name="email" id="email" type="text" placeholder=" " className="reg-input" />
            </div>
            </div> */}

            <div className="form-group" style={{"margin": "15px 0 0 0", "width":"100%"}}>
                {/* <div className="col-md-6"  style={{"padding": "0","marginRight":"10px"}} >                    */}
                <div className="profile-field">
                    <label htmlFor="first_name">{Lang.first_name+'*'}</label>
                    <CustomInput name="first_name" id="first_name" type="text" placeholder=" " className={`reg-input custom-input ${isEdit ? '' : 'readonly-field'}`} readOnly={isEdit ? false : true} doNotShowError="1"/>                
                </div>
                {touched.first_name && errors.first_name && <div className="error">{Lang[errors.first_name]}</div>}
                </div>
                {/* </div> */}
                <div className="form-group" style={{"margin": "15px 0 0 0", "width":"100%"}}>
                {/* <div className="col-md-6"  style={{"padding": "0","marginLeft":"10px"}}> */}
                <div className="profile-field">
                    <label htmlFor="last_name">{Lang.last_name+'*'}</label>
                    <CustomInput name="last_name" id="last_name" type="text" placeholder=" " className={`reg-input custom-input ${isEdit ? '' : 'readonly-field'}`} readOnly={isEdit ? false : true} doNotShowError="1" />
                </div>
                {touched.last_name && errors.last_name && <div className="error">{Lang[errors.last_name]}</div>}
                {/* </div>   */}
            </div>    

            {/* <div className="form-group" style={{"margin": "0"}}>
                <SelectInput  className="form-select" id="mobile_prefix" name="mobile_prefix">
                    <option value="+962">+962</option>
                    <option value="+971">+971</option>
                    <option value="+964">+964</option>
                    <option value="+963">+963</option>
                </SelectInput>  
                <div className="reg-field-tel">
                    <label htmlFor="mobile">{Lang.mobile_number}</label>                      
                    <CustomInput id="mobile" name="mobile" type="tel" placeholder=" " className="reg-input" />
                </div>
            </div> */}

            <div className="form-group" style={{"margin": "15px 0 0 0","width":"100%"}}>
                <div className="profile-field">
                    <label htmlFor="parent_email">{Lang.parent_email+'*'}</label>
                    <CustomInput  id="parent_email" name="parent_email" type="text" placeholder=" " className={`parent_email reg-input custom-input ${isEdit ? '' : 'readonly-field'}`} doNotShowError="1" readOnly={disableParentFeild ? 'readOnly' : ''} disabled={disableParentFeild ? 'disabled' : ''}/>
                </div>
                {touched.parent_email && errors.parent_email && <div className="error">{Lang[errors.parent_email]}</div>}

            </div>  
            <div className="form-group" style={{"margin": "15px 0 0 0","width":"100%"}}>
                <div className="profile-field">
                    <label htmlFor="password">{Lang.password+'*'}</label>
                    <CustomInput id="password" name="password" type="password" placeholder="********" className={`reg-input custom-input ${isEdit ? '' : 'readonly-field'}`} readOnly={isEdit ? false : true} doNotShowError="1" />
                </div>
                {touched.password && errors.password && <div className="error">{Lang[errors.password]}</div>}

                <span toggle="#password" className="fa fa-fw fa-eye profile-eye field-icon toggle-password"></span>
            </div>  
            {/* <div className="reg-field">
                    <label htmlFor="parent_email">{Lang.parent_email+'*'}</label>
                    {userData?.parent_email}                
                </div>  */}
            <div className={`form-group flex-group flex-group-start select-role`}  style={{"margin": "0"}}>
                <p className="select-label">{Lang.allow_sharing_voting_projects+"*"}</p>
                <div className='answers'>
                {/* <RadioInput labels={[Lang.yes, Lang.no]} ids={['ckb14','ckb15']} values={[1,0]} name="allow_sharing_projects" className="ckb ckb-dark"/> */}
                <SelectInput name="allow_sharing_projects" className={`reg-input select-date ${isEdit ? '' : 'readonly-field'}`} disabled={isEdit ? false : true} >
                     <option value={1} disabled={false}>{Lang.yes}</option>
                     <option value={0} disabled={false}>{Lang.no}</option>
                </SelectInput>                

                </div>   
            </div>  

            <div className={`form-group flex-group flex-group-start select-role`}  style={{"margin": "0"}}>
                <p className="select-label">{Lang.show_sign_language_videos+"*"}</p>
                <div className='answers'>
                {/* <RadioInput labels={[Lang.yes, Lang.no]} ids={['ckb16','ckb17']} values={[1,0]} name="show_sign_language_videos" className="ckb ckb-dark"/> */}
                <SelectInput name="show_sign_language_videos" className={`reg-input select-date ${isEdit ? '' : 'readonly-field'}`} disabled={isEdit ? false : true} >
                     <option value={1} disabled={false}>{Lang.yes}</option>
                     <option value={0} disabled={false}>{Lang.no}</option>
                </SelectInput>                
                </div>   
            </div>                               
            <LoadingButton loading={isLoading.toString()} type="submit" className="submit-button-auto save-profile hide" loading-text={`<i class='fa fa-circle-o-notch fa-spin'></i> ${Lang.processing}`} >{props.isNew ? Lang.register :Lang.save}</LoadingButton>
            </div>
        </div>
        </div>
        </Form>
  
          )}}
</Formik>


        <MessageModal title={message.title} id="general-message" okLabel="Close">
        {message.body}
        </MessageModal> 
        
        </>
    );
}

export default ChildProfile